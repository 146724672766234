import ModuleAjaxCache from 'oswedev/dist/shared/modules/AjaxCache/ModuleAjaxCache';
import ModuleAPI from 'oswedev/dist/shared/modules/API/ModuleAPI';
import ModuleDAO from 'oswedev/dist/shared/modules/DAO/ModuleDAO';
import ModuleTranslation from 'oswedev/dist/shared/modules/Translation/ModuleTranslation';
import ModuleAccessPolicy from 'oswedev/dist/shared/modules/AccessPolicy/ModuleAccessPolicy';
import ModuleFile from 'oswedev/dist/shared/modules/File/ModuleFile';
import ModuleImage from 'oswedev/dist/shared/modules/Image/ModuleImage';
import ModuleTrigger from 'oswedev/dist/shared/modules/Trigger/ModuleTrigger';
import ModulePushData from 'oswedev/dist/shared/modules/PushData/ModulePushData';
import ModuleFormatDatesNombres from 'oswedev/dist/shared/modules/FormatDatesNombres/ModuleFormatDatesNombres';
import ModuleMAILER from 'oswedev/dist/shared/modules/MAILER/ModuleMAILER';
import ModuleSASSSkinConfigurator from 'oswedev/dist/shared/modules/SASSSkinConfigurator/ModuleSASSSkinConfigurator';
import ModuleVar from 'oswedev/dist/shared/modules/Var/ModuleVar';
import ModuleTableFieldTypes from 'oswedev/dist/shared/modules/TableFieldTypes/ModuleTableFieldTypes';
import ModuleBGThread from 'oswedev/dist/shared/modules/BGThread/ModuleBGThread';
import EnvHandler from 'oswedev/dist/shared/tools/EnvHandler';
import AjaxCacheClientController from 'oswedev/dist/vuejsclient/ts/modules/AjaxCache/AjaxCacheClientController';

export default async function InitializeLoginModulesDatas() {
    EnvHandler.getInstance().NODE_VERBOSE = false;
    EnvHandler.getInstance().IS_DEV = true;
    EnvHandler.getInstance().MSGPCK = false;
    EnvHandler.getInstance().COMPRESS = false;
    EnvHandler.getInstance().BASE_URL = 'http://localhost:4405/';
    ModuleAjaxCache.getInstance().actif = true;
    ModuleAPI.getInstance().actif = true;
    ModuleDAO.getInstance().actif = true;
    ModuleTranslation.getInstance().actif = true;
    ModuleAccessPolicy.getInstance().actif = true;
    ModuleFile.getInstance().actif = true;
    ModuleImage.getInstance().actif = true;
    ModuleTrigger.getInstance().actif = true;
    ModulePushData.getInstance().actif = true;
    ModuleFormatDatesNombres.getInstance().actif = true;
    ModuleFormatDatesNombres.getInstance().setParamValue("date_format_month_date", "DD/MM");
    ModuleFormatDatesNombres.getInstance().setParamValue("date_format_fullyear_month_date", "MM/Y");
    ModuleFormatDatesNombres.getInstance().setParamValue("date_format_fullyear_month_day_date", "DD/MM/Y");
    ModuleFormatDatesNombres.getInstance().setParamValue("nombre_separateur_1000", " ");
    ModuleFormatDatesNombres.getInstance().setParamValue("nombre_separateur_decimal", ",");
    ModuleMAILER.getInstance().actif = true;
    ModuleMAILER.getInstance().setParamValue("host", "127.0.0.1");
    ModuleMAILER.getInstance().setParamValue("port", "25");
    ModuleMAILER.getInstance().setParamValue("secure", false);
    ModuleMAILER.getInstance().setParamValue("auth_user", null);
    ModuleMAILER.getInstance().setParamValue("auth_pass", null);
    ModuleMAILER.getInstance().setParamValue("from_address", "noreply@wedev.fr");
    ModuleMAILER.getInstance().setParamValue("subject_prefix", "[WEDEV] ");
    ModuleMAILER.getInstance().setParamValue("subject_suffix", null);
    ModuleSASSSkinConfigurator.getInstance().actif = true;
    ModuleVar.getInstance().actif = true;
    ModuleTableFieldTypes.getInstance().actif = true;
    ModuleBGThread.getInstance().actif = true;
    await AjaxCacheClientController.getInstance().getCSRFToken();    let promises = [];promises.push((async () => {    await ModuleAjaxCache.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleAPI.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleDAO.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleTranslation.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleAccessPolicy.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleFile.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleImage.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleTrigger.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModulePushData.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleFormatDatesNombres.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleMAILER.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleSASSSkinConfigurator.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleVar.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleTableFieldTypes.getInstance().hook_module_async_login_initialization();
})());promises.push((async () => {    await ModuleBGThread.getInstance().hook_module_async_login_initialization();
})());    await Promise.all(promises);}