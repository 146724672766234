import VueAppController from 'oswedev/dist/vuejsclient/VueAppController';

export default class LoginAppController extends VueAppController {

    public static getInstance(): LoginAppController {
        if (!LoginAppController.instance) {
            LoginAppController.instance = new LoginAppController();
        }
        return LoginAppController.instance;
    }

    private static instance: LoginAppController = null;
}