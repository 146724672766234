import 'bootstrap';
import 'jquery';
import 'metisMenu';
import LocaleManager from 'oswedev/dist/shared/tools/LocaleManager';
import DAOStore from 'oswedev/dist/vuejsclient/ts/components/dao/store/DaoStore';
import OnPageTranslationStore from 'oswedev/dist/vuejsclient/ts/components/OnPageTranslation/store/OnPageTranslationStore';
import AppVuexStoreManager from 'oswedev/dist/vuejsclient/ts/store/AppVuexStoreManager';
import VueAppBase from 'oswedev/dist/vuejsclient/VueAppBase';
import 'select2';
import LoginAppController from './LoginAppController';
import InitializeLoginModulesDatas from './ts/generated/InitializeLoginModulesDatas';
import LoginVueMain from 'oswedev/dist/vuejsclient/login/main/LoginVueMain';
import AccessPolicyLoginVueModule from 'oswedev/dist/vuejsclient/login/AccessPolicy/AccessPolicyLoginVueModule';

export default class LoginApp extends VueAppBase {
    public static getInstance(): LoginApp {
        if (!LoginApp.instance) {
            LoginApp.instance = new LoginApp();
        }
        return LoginApp.instance;
    }

    private static instance: LoginApp;

    protected constructor() {
        super(LoginAppController.getInstance(), InitializeLoginModulesDatas);
    }

    public createVueMain() {

        AppVuexStoreManager.getInstance().registerModule(DAOStore.getInstance());
        AppVuexStoreManager.getInstance().registerModule(OnPageTranslationStore.getInstance());

        AppVuexStoreManager.getInstance().configure_store();

        return new LoginVueMain({
            router: this.vueRouter,
            store: AppVuexStoreManager.getInstance().appVuexStore,
            i18n: LocaleManager.getInstance().i18n
        } as any);
    }

    protected async initializeVueAppModulesDatas() {
        AccessPolicyLoginVueModule.getInstance();
    }
}

LoginApp.getInstance().runApp();
